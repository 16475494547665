import { Feature } from 'geojson';
import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import constants from '../../../../shared/constants';
import { ReportType } from '../../../../utils/enums';
import { Insights } from '../../../../utils/interfaces';
import { addPopupOnListElement } from '../../../../utils/map';
import { highlightFeature } from '../../../../shared/components/Map';
import mapboxgl from 'mapbox-gl';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const SearchBar = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 0;
    box-sizing: border-box;
    background: #414247;
    color: white;
    border: none;
    font-size: 17px;
    line-height: 25px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: white;
    }
`;
const SearchItem = styled.div<{ selected: Boolean }>`
    padding: 8px;
    background: ${(props) => (props.selected ? '#414247' : constants.colors.pallet.lightBlack)};
    opacity: 1;
    color: white;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    span {
        margin-left: 8px;
    }
    &:hover {
        background: #414247;
    }
`;
const SearchResults = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    max-height: 500px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0 0 5px 5px;
    overflow-y: scroll;
    z-index: 1000;
    top: 49px;
    width: 100%;
`;

const CircleDot = styled.div`
    display: inline-block;
    width: 11px;
    height: 11px;
    background: #008bcc;
    border-radius: 50%;
    max-width: 12px;
`;

type ISearchResult = {
    undetected_ais: Feature[] | undefined;
    matched_vessels: Feature[] | undefined;
};

export const SearchForMmsi: FC<{
    currMap: undefined | mapboxgl.Map;
    reportDetectionsData: Insights | undefined;
    popupRef: React.MutableRefObject<mapboxgl.Popup>;
}> = ({ currMap, reportDetectionsData, popupRef }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<ISearchResult | undefined>(undefined);
    const [activeEl, setActiveEl] = useState<number>(-1);
    const [selectedPoint, setSelectedPoint] = useState<
        undefined | { point: mapboxgl.MapboxGeoJSONFeature; color: string }
    >(undefined);

    const handleKeyDown = (e: any) => {
        let joinedSearchResults: mapboxgl.MapboxGeoJSONFeature[] = (
            searchResults ? [...(searchResults.matched_vessels || []), ...(searchResults.undetected_ais || [])] : []
        ) as mapboxgl.MapboxGeoJSONFeature[];
        if (e.keyCode === 38 && activeEl > -1 && searchResults) {
            setActiveEl(activeEl - 1);
        } else if (e.keyCode === 40 && activeEl < joinedSearchResults.length - 1 && searchResults) {
            setActiveEl(activeEl + 1);
        } else {
            setActiveEl(-1);
        }
    };

    useEffect(() => {
        let joinedSearchResults: mapboxgl.MapboxGeoJSONFeature[] = (
            searchResults ? [...(searchResults.matched_vessels || []), ...(searchResults.undetected_ais || [])] : []
        ) as mapboxgl.MapboxGeoJSONFeature[];
        setSelectedPoint({
            point: joinedSearchResults[activeEl],
            color:
                searchResults && searchResults.matched_vessels && searchResults.matched_vessels.length > activeEl
                    ? constants.colors.markers.matchedVessels.fill
                    : constants.colors.markers.undetectedAis.fill,
        });
    }, [activeEl, searchResults, setActiveEl]);

    useEffect(() => {
        if (currMap && typeof currMap.getLayer('selectedCircle') !== 'undefined') {
            currMap.removeLayer('selectedCircle');
            currMap.removeSource('selectedCircle');
        }
        if (selectedPoint && selectedPoint.point && currMap) {
            highlightFeature(currMap, selectedPoint.point, selectedPoint.color, (hei: boolean) => true);
        }
    }, [setSelectedPoint, selectedPoint, currMap]);

    useEffect(() => {
        if (
            currMap &&
            reportDetectionsData &&
            selectedPoint &&
            selectedPoint.point &&
            selectedPoint.point.geometry.type === 'Point'
        ) {
            addPopupOnListElement(currMap, popupRef, selectedPoint.point, ReportType.InteractiveReport, [
                selectedPoint.point.geometry.coordinates[0],
                selectedPoint.point.geometry.coordinates[1],
            ]);
        }
    }, [currMap, reportDetectionsData, selectedPoint, popupRef]);

    useEffect(() => {
        const addSearchResults = (name: string) => {
            if (currMap && currMap.getSource(name)) {
                currMap.removeLayer(name);
                currMap.removeSource(name);
            }
        };

        if (reportDetectionsData) {
            let searchResult = `search_result_layers`;
            if (searchValue !== '') {
                let newResults = {
                    undetected_ais: reportDetectionsData?.undetected_ais?.features?.filter((v) =>
                        v?.properties?.mmsi.toString().startsWith(searchValue)
                    ),
                    matched_vessels: reportDetectionsData?.matched_vessels?.features?.filter((v) =>
                        v?.properties?.mmsi?.toString().startsWith(searchValue)
                    ),
                };
                setSearchResults(newResults);
                if (currMap && newResults) {
                    newResults.undetected_ais && addSearchResults(`${searchResult}_ais`);
                    newResults.matched_vessels && addSearchResults(`${searchResult}_vessels`);
                }
            } else {
                if (currMap && currMap.getSource(searchResult)) {
                    currMap.removeLayer(searchResult);
                    currMap.removeSource(searchResult);
                }
                setSearchResults(undefined);
            }
        }
    }, [searchValue, currMap, reportDetectionsData]);

    return (
        <Wrapper id="mmsi_search">
            <SearchBar
                placeholder="Search for MMSI"
                value={searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
            ></SearchBar>

            {searchResults && (
                <SearchResults>
                    {searchResults?.matched_vessels?.map((s, i) => (
                        <SearchItem
                            selected={activeEl === i}
                            onMouseEnter={() => {
                                setActiveEl(i);
                            }}
                            key={i}
                        >
                            <img src="/images/icons/summary/detected.svg" alt="" />
                            <span>{s?.properties?.mmsi}</span>
                        </SearchItem>
                    ))}
                    {searchResults?.undetected_ais?.map((s, i) => {
                        let searchKey =
                            searchResults && searchResults.matched_vessels?.length
                                ? searchResults.matched_vessels?.length + i
                                : i;
                        return (
                            <SearchItem
                                selected={activeEl === searchKey}
                                onMouseEnter={() => {
                                    setActiveEl(searchKey);
                                }}
                                key={searchKey}
                            >
                                <CircleDot />
                                <span>{s?.properties?.mmsi}</span>
                            </SearchItem>
                        );
                    })}
                </SearchResults>
            )}
        </Wrapper>
    );
};
