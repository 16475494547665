import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useInput } from '../components/useInput';
import { InputField } from '../../../shared/styles/InputFields';
import { InputButton } from '../../../shared/styles/Buttons';
import constants from '../../../shared/constants';
import { orderPathfinderSubscription, useIsAdmin } from '../../../requests/reports';
import { DateSelectorWithYears } from '../interactiveReport/reportInfo/DateSelector';
import { useAuth } from '../../../auth/AuthProvider';
import { AbsoluteSpinner } from '../interactiveReport/PathfinderReport';

const OrderReportWrapper = styled.div`
    width: calc(${constants.sizes.container} - 800px);
    max-width: calc(100% - 100px);
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 5px;
    background: radial-gradient(42.95% 171.27% at 86.22% 0%, #00a1ff 0%, rgba(0, 161, 255, 0.4) 100%);
    mix-blend-mode: difference;

    margin: auto;
    padding: 25px;
    margin-top: 300px;
    margin-bottom: 100px;

    p {
        margin: 0;
        margin-bottom: 10px;
    }
    h2 {
        margin: 0 0 15px 0;
    }
    label {
        font-weight: bold;
        font-size: 10px;
        line-height: 25px;
        /* identical to box height, or 250% */

        letter-spacing: 0.07em;

        color: rgba(255, 255, 255, 0.6);
        a {
            color: rgba(255, 255, 255, 0.6);
        }
    }
    form {
        margin-bottom: 10px;
    }

    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
        align-items: center;
        width: calc(100% - 50px);
        p {
            margin: 0;
            width: 100%;
        }
    }
`;

const InputWrapper = styled.div`
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    ${InputField} {
        width: calc(100% - 50px);
        margin-right: 10px;
    }

    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
        ${InputField} {
            width: 100%;
        }
    }
`;

const InputRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    label {
        margin-right: 10px;
    }
`;

const StyledInputField = styled(InputField)`
    width: 80%;
    line-height: normal;
`;

const StyledInputButton = styled(InputButton)<{ active?: Boolean }>`
    /* background: ${(props) => !props.active && 'grey'}; */
    /* border-color: ${(props) => !props.active && 'grey'}; */
    cursor: ${(props) => !props.active && 'default'};
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

//Validation:
const required = (text: string, fieldName: string) => {
    if (text) {
        return '';
    }
    return `${fieldName} is required`;
};

const validMmsi = (value: string, fieldName: string) => {
    const onlyNumbersRegex = /^\d+$/;
    if (onlyNumbersRegex.test(value) && value.length === 9) {
        return '';
    }
    return `${fieldName} is not a valid MMSI`;
};

const xMonthsAgo = (months: number): Date => {
    let date = new Date();
    date.setMonth(date.getMonth() - months);
    return date;
};

const getDate = (date: Date | null) => {
    if (date) return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return date;
};

// TODO: Prevent user to set fromDate later than toDate
const OrderPathfinderReport: FC = () => {
    const { token } = useAuth();
    const { data: isAdmin, isLoading, isFetched } = useIsAdmin(token);
    const [orderSubscriptionSuccess, setOrderSubscriptionSuccess] = useState<boolean | undefined>(undefined);
    const [reportPath, setReportPath] = useState<string | undefined>(undefined);
    const [fromDate, setFromDate] = useState<Date | null>(xMonthsAgo(3));
    const [toDate, setToDate] = useState<Date | null>(new Date());

    useEffect(() => {
        document.title = 'Order Pathfinder';
    }, []);

    const mmsi = useInput('', 'MMSI', [required, validMmsi]);

    const areAllFieldsValid = () => {
        // Add checks for mmsi, fromDate and toDate (if set)
        return Boolean(mmsi.validation.touched && !mmsi.validation.errors.length);
    };

    const allFieldsValid = areAllFieldsValid();

    const resetFields = useCallback(() => {
        mmsi.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const orderSubscriptionCallback = (requestSuccess: boolean, reportPath: string | undefined) => {
        if (requestSuccess) resetFields();
        setOrderSubscriptionSuccess(requestSuccess);
        setReportPath(reportPath);
    };

    const submitLoginForm = (e: any) => {
        e.preventDefault();
        if (allFieldsValid) {
            orderPathfinderSubscription(
                mmsi.value,
                getDate(fromDate)!,
                getDate(toDate),
                token,
                (orderSubscriptionSuccess: boolean, reportPath: string | undefined) =>
                    orderSubscriptionCallback(orderSubscriptionSuccess, reportPath)
            );
        }
    };

    return (
        <OrderReportWrapper>
            {isLoading && <AbsoluteSpinner />}
            {isFetched && (
                <>
                    <h2>Order VAKE Pathfinder</h2>
                    <form onSubmit={(e) => submitLoginForm(e)}>
                        <InputWrapper>
                            <InputRow>
                                <label htmlFor="mmsi">MMSI</label>
                                <StyledInputField
                                    id="mmsi"
                                    type="mmsi"
                                    error={mmsi.validation.errors.length > 0}
                                    {...mmsi.bind}
                                />
                            </InputRow>
                            <InputRow>
                                <label htmlFor="start-date">Start date</label>
                                <DateSelectorWithYears date={fromDate} setDate={setFromDate} />
                            </InputRow>
                            <InputRow>
                                <label htmlFor="end-date">End date</label>
                                <DateSelectorWithYears date={toDate} setDate={setToDate} />
                            </InputRow>
                        </InputWrapper>
                        <ButtonWrapper>
                            <StyledInputButton
                                background={constants.colors.pallet.white}
                                disabled={!allFieldsValid}
                                active={allFieldsValid}
                                type="submit"
                                value="Order"
                            />
                        </ButtonWrapper>
                    </form>
                    {orderSubscriptionSuccess && reportPath && (
                        <p style={{ color: constants.colors.pallet.white }}>
                            Order sent successfully. In some minutes, you will find the report{' '}
                            <Link to={reportPath}>here.</Link>
                        </p>
                    )}
                    {orderSubscriptionSuccess === false && (
                        <p style={{ color: constants.colors.pallet.error }}>Order failed.</p>
                    )}
                </>
            )}
        </OrderReportWrapper>
    );
};

export default OrderPathfinderReport;
