/// app.js
import React, { FC, useEffect, useState } from 'react';
import DeckGL from '@deck.gl/react/typed';
import { H3HexagonLayer } from '@deck.gl/geo-layers/typed';
import StaticMap from 'react-map-gl';
import { scaleQuantile } from 'd3-scale';
import { vake_api_server_url } from '../../../requests/helpers';

// Viewport settings
const INITIAL_VIEW_STATE = {
    longitude: 12.809301,
    latitude: 64.752461,
    zoom: 4,
    pitch: 0,
    bearing: 0,
};

function hexToRgba(hex: string): number[] {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
    if (!result) {
        return [0, 0, 0, 1]; // Return black with full opacity if input is invalid
    }
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    const a = 255; // Convert alpha to a value between 0 and 1
    return [r, g, b, a];
}

const getHexLayer = (data: any, colorScaler: any): any => {
    return new H3HexagonLayer({
        id: 'H3HexagonLayer',
        data: data,
        extruded: false,
        getFillColor: (d: any) => {
            var color = colorScaler(d.amount);
            color = hexToRgba(color);
            return color;
        },
        getHexagon: (d) => d.h3_08,
        stroked: false,
        wireframe: false,

        /* props inherited from Layer class */

        autoHighlight: true,
        pickable: true,
    });
};

export const DensityMap: FC = () => {
    const [data, setData] = useState<any[]>();
    const [layers, setLayers] = useState<any[]>([]);

    useEffect(() => {
        document.title = 'Density Map';
    }, []);

    useEffect(() => {
        fetch(`${vake_api_server_url}/density/hex`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data);
            });
    }, []);

    useEffect(() => {
        if (data) {
            const values = data.map((d) => d.amount);
            var quantile = scaleQuantile<string>()
                .domain(values)
                .range(['#5A1846', '#900C3F', '#C70039', '#E3611C', '#F1920E', '#FFC300']);
            setLayers([getHexLayer(data, quantile)]);
        }
    }, [data]);

    return (
        <DeckGL initialViewState={INITIAL_VIEW_STATE} controller={true} layers={layers}>
            <StaticMap
                mapStyle="mapbox://styles/vake-torstein/cl4xzn1gs000p14qgz8nqs0ms"
                mapboxAccessToken="pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw"
            />
        </DeckGL>
    );
};
export default DensityMap;
