import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const MapContainer = styled.div<{ isLoadingData: Boolean }>`
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isLoadingData ? 0.5 : 1)};
`;

export const OrderOverwatchMap: React.FC<{
    containerRef: React.RefObject<HTMLDivElement>;
    drawMode: boolean;
    polygon: Feature | undefined;
    setPolygon: (value: Feature | undefined) => void;
}> = ({ containerRef, drawMode, polygon, setPolygon }) => {
    const [currMap, setMap] = useState<undefined | mapboxgl.Map>(undefined);
    const [drawObj, setDrawObj] = useState<null | MapboxDraw>(null);
    const [getPolygon, setGetPolygon] = useState<boolean>(false); // State needed to add and delete eventlistener
    useEffect(() => {
        if (containerRef.current && !currMap) {
            const coords = {
                lat: 57.33870347771735,
                lng: 2.042840975438542,
            };
            mapboxgl.accessToken =
                'pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw';
            const maps: mapboxgl.Map = new mapboxgl.Map({
                container: containerRef.current,
                center: coords,
                zoom: 5,
                style: 'mapbox://styles/vake-torstein/cl4xzn1gs000p14qgz8nqs0ms',
            });
            maps.on('load', () => {
                maps.loadImage('/images/icons/ArrowRight.png', (error: any, image: any) => {
                    if (error) {
                        throw error;
                    }
                    maps.addImage('arrow_right', image);
                });
                setMap(maps);
                maps.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
                maps.addControl(new mapboxgl.ScaleControl({ maxWidth: 500 }));
            });
        }
    }, [currMap, containerRef]);

    /**
     * Part under is for drawing logic
     */

    useEffect(() => {
        // useEffect for adding layers to map when draw mode is toggled
        if (currMap) {
            if (drawMode) {
                // New drawingmode
                const draw = new MapboxDraw({
                    displayControlsDefault: false,
                    // Select which mapbox-gl-draw control buttons to add to the map.
                    controls: {
                        polygon: true,
                        trash: true,
                    },
                    defaultMode: 'draw_polygon',
                });
                setDrawObj(draw);
            } else {
                // Drawing mode off. Resett everything
                setPolygon(undefined);
                currMap.off('draw.create', drawCreate); // Removing event listener. Needs same function given to the eventadding
                if (currMap && typeof currMap.getLayer('aoi_outline') !== 'undefined') {
                    currMap.removeLayer('aoi_outline');
                    currMap.removeLayer('aoi_fill');
                    currMap.removeSource('aoi');
                }
                setGetPolygon(false);
                if (drawObj && !!!polygon) {
                    // If the user deactivates drawing after not finishing polygon
                    currMap.removeControl(drawObj);
                    setDrawObj(null);
                }
            }
        }
    }, [currMap, drawMode]);

    useEffect(() => {
        if (currMap && polygon) {
            AddAoI(currMap, polygon);
        }
    }, [polygon, currMap]);

    useEffect(() => {
        // useEffect made for
        if (drawObj && getPolygon) {
            const newAoI: Geometry = drawObj.getAll()['features'][0]['geometry'];
            setPolygon({ type: 'Feature', geometry: newAoI, properties: { name: 'Area of Interest' } });
        }
    }, [getPolygon]);

    const drawCreate = () => {
        setGetPolygon(!getPolygon);
    };

    useEffect(() => {
        // When the draw object has been created or removed, cleanup
        if (currMap) {
            if (drawObj && drawMode) {
                // Add eventlistener and control to map
                currMap.addControl(drawObj, 'bottom-left');
                currMap.on('draw.create', drawCreate);
            } else if (drawObj) {
                // Remove eventllistener and control
                currMap.removeControl(drawObj);
                setDrawObj(null);
            }
        }
    }, [drawObj]);

    useEffect(() => {
        if (currMap && polygon && drawObj) {
            AddAoI(currMap, polygon);
            currMap.removeControl(drawObj);
        }
    }, [polygon, setPolygon]);

    /**
     * Part over is for drawing logic
     */

    return <MapContainer ref={containerRef} isLoadingData={false} />;
};

const AddAoI = (currMap: mapboxgl.Map, polygon: Feature) => {
    if (currMap && !currMap.getSource('aoi')) {
        currMap
            .addSource('aoi', {
                type: 'geojson',
                data: polygon,
            })
            .addLayer({
                id: 'aoi_outline',
                type: 'line',
                source: 'aoi',
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-color': '#555',
                    'line-width': 3,
                },
            })
            .addLayer({
                id: 'aoi_fill',
                type: 'fill',
                source: 'aoi', // reference the data source
                layout: {},
                paint: {
                    'fill-color': '#0080ff', // blue color fill
                    'fill-opacity': 0.3,
                },
            });
    }
};
