import axios from 'axios';
import { apiBaseUrl } from '../utils/env';

const vake_api_server_url = apiBaseUrl();

export const postContact = (name: string, email: string, companyName: string, newsletter: true, callback: Function) => {
    axios
        .post(
            `${vake_api_server_url}/crm/contact`,
            {
                email: email,
                name: name,
                company_name: companyName,
                newsletter: true,
            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }
        )
        .then(() => callback(true))
        .catch(() => callback(false));
};

export const postReportRequest = (
    name: string,
    email: string,
    companyName: string,
    newsletter: boolean,
    aoi: string,
    timeframe: string,
    callback: Function
) => {
    axios
        .post(
            `${vake_api_server_url}/crm/report_request`,
            {
                email: email,
                name: name,
                company_name: companyName,
                newsletter: newsletter,
                area_of_interest: aoi,
                timeframe: timeframe,
            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }
        )
        .then(() => callback(true))
        .catch(() => callback(false));
};
