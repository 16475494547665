import styled from 'styled-components';
import constants from '../../../shared/constants';

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RoundedWrapper = styled(ColumnWrapper)<{ background?: string }>`
    background: ${(props) => (props.background ? props.background : constants.colors.pallet.lightBlack)};
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
    width: 100%;
`;

export const PaddingWrapper = styled(ColumnWrapper)`
    padding: 15px 20px;
    h2 {
        margin: 0;
    }
    p {
        margin-top: 10px;
    }
`;
export const BorderWrapper = styled(PaddingWrapper)<{ color?: string }>`
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    color: ${(props) => (props.color ? props.color : 'rgba(255, 255, 255, 0.6)')};
`;

export const BorderFreeRounded = styled(RoundedWrapper)`
    border: none;
`;

export const Border = styled(PaddingWrapper)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Title = styled.h4`
    font-weight: 700;
`;

export const InfoWrapper = styled.div`
    position: absolute;
`;

export const CenteredElement = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
`;
