import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMetadataQuery, usePathfinderData } from '../../../requests/reports';
import { Spinner } from '../../../shared/styles/Spinner';
import PathfinderMap from '../../../shared/components/PathfinderMap';
import { ReportType } from '../../../utils/enums';
import { useParams } from 'react-router-dom';
import { PathfinderData } from '../../../utils/interfaces';
import { PathfinderList } from '../components/PathfinderList';
import { Feature } from 'geojson';
import { useVesselNameSearch } from '../../../requests/search';
import { PathfinderLeftSidebar } from '../components/PathfinderLeftSidebar';
import { BorderFreeRounded } from '../styles/Wrappers';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;

export const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;

export const ColorBall = styled.div<{ $backgroundColor?: string | null }>`
    height: 16px;
    width: 16px;
    border-radius: 17px;
    background-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : '#ecda80')};
    align-self: center;
    text-align: center;
    margin-right: 10px;
`;

const Shader = styled(Wrapper)`
    z-index: 1;
    background-color: rgba(30, 31, 85, 0.5);
`;

const CenteredErrorMessage = styled(BorderFreeRounded)`
    position: absolute;
    top: 45%;
    left: 45%;
    width: 30vw;
    padding: 2vw;
    text-align: center;
    align-items: center;
`;

export interface IChosenBoatObject {
    lon?: number;
    lat?: number;
    timestamp?: string;
    image_path?: number;
    id?: number | string;
}

export const PathfinderReport: FC = () => {
    const params = useParams();
    const [mmsi, setMmsi] = useState<string>('');
    const { data: reportMetadata } = useMetadataQuery(params, ReportType.PathfinderReport);
    const { data: pathfinderData, isLoading } = usePathfinderData(params);
    const { data: searchResults = [] } = useVesselNameSearch(mmsi);

    const containerRef = useRef<HTMLDivElement>(null);
    const [fromDate, setFromDate] = useState<string>('');
    const [toDate, setToDate] = useState<string>('');
    const [chosenBoatObject, setChosenBoatObject] = useState<IChosenBoatObject | null>(null);
    const [hoveredPathElement, setHoveredPathElement] = useState<number | null>(null);
    const [boatMetaData, setBoatMetaData] = useState<Feature | null>(null);
    const [shipName, setShipName] = useState<string | null>(null);
    const [shipImo, setShipImo] = useState<string | null>(null);
    const [havePlanet, setHavePlanet] = useState<boolean>(false);
    const [hasOilPipelines, setHasOilPipelines] = useState<boolean>(false);
    const [hasAisPositionData, setHasAisPositionData] = useState<boolean>(true);

    useEffect(() => {
        document.title = 'Pathfinder';
    }, []);

    useEffect(() => {
        if (searchResults.length === 1 && searchResults[0].name) {
            setShipName(searchResults[0].name);
            setShipImo(searchResults[0]?.imo);
        }
    }, [searchResults]);

    // Setting report params such as mmsi, timestamps and name (if exists)
    useEffect(() => {
        const incomingParameters = typeof params['id'] !== 'undefined' ? params['id'].split('_') : [];
        if (incomingParameters.length >= 3) {
            setMmsi(incomingParameters[0]);
            const from = incomingParameters[1].split('T')[0];
            const to = incomingParameters[2].split('T')[0];
            setFromDate(from);
            setToDate(to);
        } else if (incomingParameters.length === 1) {
            if (typeof incomingParameters[0] === 'string') {
                const name = incomingParameters[0].charAt(0).toUpperCase() + incomingParameters[0].slice(1);
                setShipName(name);
            }
        }
    }, [params]);

    useEffect(() => {
        if (pathfinderData) {
            if (!pathfinderData.ais_positions && !pathfinderData.ais_tracks) {
                setHasAisPositionData(false);
            } else {
                setHasAisPositionData(true);
            }
            if (pathfinderData.ais_positions && pathfinderData.ais_positions.features) {
                pathfinderData.ais_positions.features.forEach((element) => {
                    if (!havePlanet && element.properties?.image_source === 'planet_scope') {
                        setHavePlanet(true);
                    }
                });
            }
            if (pathfinderData.oil_pipelines) {
                setHasOilPipelines(true);
            }
        } else if (!isLoading) {
            setHasAisPositionData(false);
        }
    }, [pathfinderData, isLoading, havePlanet]);

    useEffect(() => window.scrollTo(0, 0), []);
    return (
        <Wrapper>
            {params ? (
                <>
                    <PathfinderLeftSidebar
                        shipName={shipName}
                        mmsi={mmsi}
                        shipImo={shipImo}
                        fromDate={fromDate}
                        toDate={toDate}
                        havePlanet={havePlanet}
                        hasOilPipelines={hasOilPipelines}
                    />
                    {pathfinderData && pathfinderData.ais_positions ? (
                        <PathfinderList
                            aisPositions={pathfinderData?.ais_positions}
                            chosenBoatObject={chosenBoatObject}
                            boatMetaData={boatMetaData}
                            setBoatMetaData={setBoatMetaData}
                            setHoveredPathElement={setHoveredPathElement}
                            mmsi={mmsi}
                            shipName={shipName}
                        />
                    ) : !hasAisPositionData && !isLoading ? ( // Error handling of no AIS messages
                        <Shader>
                            <CenteredErrorMessage>
                                No data was found for this report.
                                <div style={{ width: '20%', maxWidth: '45px' }}>
                                    <img width={'100%'} src="/images/icons/satellite.svg" alt="no source" />
                                </div>
                                It can take a few minutes to generate. <br />
                                However, if it still does not appear, send us an email and we will be happy for the
                                feedback.
                            </CenteredErrorMessage>
                        </Shader>
                    ) : null}
                </>
            ) : null}
            {isLoading && <AbsoluteSpinner />}
            <PathfinderMap
                containerRef={containerRef}
                pathfinderData={pathfinderData}
                reportMetadata={reportMetadata}
                isDataLoading={isLoading}
                boatMetaData={boatMetaData}
                setChosenBoatObject={setChosenBoatObject}
                hoveredPathElement={hoveredPathElement}
            />
        </Wrapper>
    );
};
