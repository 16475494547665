import React, { useRef, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import { Feature, FeatureCollection } from 'geojson';
import { Insights } from '../../utils/interfaces';
import { ReportType } from '../../utils/enums';
import { parseGeoJsonPropertiesNullValues } from '../../utils/map';
import { QueryClientProvider } from 'react-query';
import { ReportPopup, TargetsOfInterestPopup } from './Popup';
import { queryClient } from '../../App';
import constants from '../constants';
import { useAuth } from '../../auth/AuthProvider';
import { useIsAdmin } from '../../requests/reports';

const MapContainer = styled.div<{ isLoadingData: Boolean }>`
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isLoadingData ? 0.5 : 1)};
`;

export interface ILayer {
    id: string;
    name: string;
    date: string;
    static?: boolean;
    data: FeatureCollection | null;
    fill: any;
    stroke?: string;
    filter?: string[];
    fillHover?: 'string';
    strokeHover?: 'string';
    type: 'line' | 'circle' | 'fill';
    fillOpacity?: number;
    popupType?: 'detection' | 'toi';
    circleRadius?: number;
    cluster?: boolean;
}

const Map: React.FC<{
    insights: Insights | undefined;
    clouds: any | undefined;
    metaData: any | undefined;
    activeDate: Date | undefined;
    availableDates: Date[] | undefined;
    isInsightsLoading: Boolean;
    map: undefined | mapboxgl.Map;
    setMap: (map: mapboxgl.Map | undefined) => void;
    popupRef: React.MutableRefObject<mapboxgl.Popup>;
    setVisibleLayers: (layers: string[] | undefined) => void;
}> = ({
    insights,
    clouds,
    metaData,
    activeDate,
    availableDates,
    isInsightsLoading,
    map,
    setMap,
    popupRef,
    setVisibleLayers,
}) => {
    const { token } = useAuth();
    const { data: isAdmin } = useIsAdmin(token);
    const containerRef = useRef<HTMLDivElement>(null);
    const [layers, setLayers] = useState<ILayer[]>([]);
    // initialize map
    useEffect(() => {
        if (metaData && !map) {
            let zmm = 6;
            let center_pos = metaData.metadata.center_pos;
            let coords = {
                lat: center_pos[1],
                lng: center_pos[0],
            };
            mapboxgl.accessToken =
                'pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw';
            zmm = metaData.metadata.zoom;
            const new_map: mapboxgl.Map = new mapboxgl.Map({
                container: containerRef.current!,
                center: coords,
                zoom: zmm,
                style: 'mapbox://styles/vake-torstein/cl4xzn1gs000p14qgz8nqs0ms',
            });

            //Broadcast loaded
            new_map.on('load', () => {
                new_map.addControl(new mapboxgl.ScaleControl({ maxWidth: 500 }));
                new_map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
                setMap(new_map);
            });
        }
    }, [map, metaData, setMap]);

    const addMapLayers = () => {
        let insight_id = insights!.id;
        let clouds_id = clouds && clouds.features.length > 0 ? clouds.features[0].id : '-1';

        let colors = constants.colors.markers;
        let new_layers: ILayer[] = [
            {
                id: `footprints_${insight_id}`,
                name: 'footprints',
                date: insight_id,
                static: true,
                data: insights!.product_footprints,
                fill: '#00A1FF',
                fillOpacity: 0.3,
                type: 'fill',
            },
            {
                id: `cloudmasks_${clouds_id}`,
                name: 'cloudmasks',
                date: insight_id,
                static: true,
                data: clouds!,
                fill: '#c0e0f3',
                fillOpacity: 0.2,
                type: 'fill',
            },
            {
                id: `target_of_interest_aoi_${insight_id}`,
                name: 'target_of_interest_aoi',
                date: insight_id,
                data: insights!.targets_of_interest,
                fill: '#00A1FF',
                fillOpacity: 0.1,
                type: 'fill',
            },
            {
                id: `target_of_interest_${insight_id}`,
                name: 'target_of_interest',
                date: insight_id,
                data: insights!.targets_of_interest
                    ? {
                          type: 'FeatureCollection',
                          features: insights!.targets_of_interest.features.map((feat: Feature, i: number) => {
                              let feature: Feature = {
                                  id: i.toString(),
                                  type: 'Feature',
                                  geometry: {
                                      type: 'Point',
                                      coordinates: [feat!.properties!.lon, feat!.properties!.lat],
                                  },
                                  properties: feat.properties,
                              };
                              return feature;
                          }),
                      }
                    : null,
                fill: colors.darkShips.fill,
                stroke: colors.darkShips.stroke,
                type: 'circle',
                popupType: 'toi',
            },
            {
                id: `static_objects_${insight_id}`,
                name: 'static_objects',
                date: insight_id,
                static: true,
                data: insights!.static_objects,
                fill: '#000',
                stroke: '#00A1FF',
                type: 'circle',
                popupType: 'detection',
            },
            {
                id: `undetected_ais_${insight_id}`,
                name: 'undetected_ais',
                date: insight_id,
                data: insights!.undetected_ais,
                fill: colors.undetectedAis.fill,
                stroke: colors.undetectedAis.stroke,
                type: 'circle',
                popupType: 'detection',
                circleRadius: 3,
            },
            {
                id: `dark_vessels_${insight_id}`,
                name: 'dark_vessels',
                date: insight_id,
                data: insights!.dark_vessels,
                fill: colors.darkShips.fill,
                stroke: colors.darkShips.stroke,
                type: 'circle',
                popupType: 'detection',
            },
            {
                id: `matched_vessels_${insight_id}`,
                name: 'matched_vessels',
                date: insight_id,
                data: insights!.matched_vessels,
                fill: colors.matchedVessels.fill,
                stroke: colors.matchedVessels.stroke,
                type: 'circle',
                popupType: 'detection',
            },
        ];

        new_layers = new_layers.filter((layer) => layer.data);
        new_layers = new_layers.filter((layer) => !layers.includes(layer));

        new_layers.forEach((layer) => {
            if (layer.type === 'circle') {
                addCircleLayer(
                    map!,
                    layer.data,
                    layer.id,
                    layer.fill,
                    layer.stroke!,
                    popupRef,
                    layer.circleRadius!,
                    setHiglightedFeatureVisibility,
                    layer.popupType,
                    isAdmin
                );
            } else if (layer.type === 'fill') {
                addFillLayer(map!, layer.data, layer.id, layer.fill, layer.fillOpacity!);
            }
        });

        setLayers(layers.concat(new_layers));
    };

    // useEffect for adding layers to map
    useEffect(() => {
        if (map && insights && metaData) {
            if (insights.targets_of_interest) {
                let properties = insights.targets_of_interest.features[0].properties;
                map.flyTo({
                    center: [properties!.lon, properties!.lat],
                });
            }
            !insights.targets_of_interest && addAoiLayerToMap(map, metaData);
            addMapLayers();
        } // eslint-disable-next-line
    }, [map, insights, activeDate, metaData, clouds]);

    // useEffect for changing visibility of layers
    useEffect(() => {
        if (map && layers && availableDates && insights && activeDate) {
            layers.forEach((layer: ILayer) => {
                if (layer.date !== activeDate.toISOString().split('T')[0]) {
                    setLayerVisibility(map, false, layer.id);
                } else {
                    setLayerVisibility(map, true, layer.id);
                }
            });
            let visibleLayers = layers
                .filter((layer) => layer.date === activeDate.toISOString().split('T')[0])
                .map((layer) => layer.id);
            setVisibleLayers(visibleLayers); // this is set for the map legend in reportInfo
        }
    }, [map, layers, activeDate, availableDates, insights, clouds, setVisibleLayers]);

    const setHiglightedFeatureVisibility = (visible: boolean) => {
        setLayerVisibility(map!, visible, 'selectedCircle');
    };

    return <MapContainer ref={containerRef} isLoadingData={isInsightsLoading} />;
};

export default Map;

export const addFillLayer = (
    currMap: mapboxgl.Map,
    data: any,
    id: string,
    fill: string,
    fillOpacity?: number,
    filter?: string[]
) => {
    if (currMap && !currMap.getSource(id)) {
        let layerObj: any = {
            id: id,
            type: 'fill',
            source: id,
            layout: {
                // make layer visible by default
                visibility: 'visible',
            },
            paint: {
                'fill-color': fill,
                'fill-opacity': fillOpacity ? fillOpacity : 0.3,
            },
        };
        if (filter) {
            layerObj['filter'] = filter;
        }
        currMap
            .addSource(id, {
                type: 'geojson',
                data: data,
            })
            .addLayer(layerObj);
    }
};

const addAoiLayerToMap = (map: mapboxgl.Map, reportMetadata: any) => {
    let aoi = {
        type: 'FeatureCollection',
        features: reportMetadata.features,
    } as FeatureCollection;

    if (aoi && !map.getSource('aoi')) {
        map.addSource('aoi', {
            type: 'geojson',
            data: aoi,
        });
        map.addLayer({
            id: 'aoi',
            type: 'line',
            source: 'aoi',
            layout: {},
            paint: {
                'line-color': '#00A1FF',
            },
        });
    }
};

export const addPopup = (
    currMap: mapboxgl.Map,
    layer_id: string,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    reportType: ReportType,
    popupType: 'detection' | 'toi',
    setHiglightedFeatureVisibility: (visible: boolean) => void,
    isAdmin?: boolean
) => {
    currMap.on('click', layer_id, (e) => {
        if (e.features && e.features.length) {
            const feature = e.features[0];
            feature.properties = parseGeoJsonPropertiesNullValues(feature.properties);
            // create popup node
            const popupNode = document.createElement('div');
            const popupRoot = createRoot(popupNode);

            popupRoot.render(
                <QueryClientProvider client={queryClient}>
                    {popupType === 'detection' ? (
                        <ReportPopup feature={feature.properties} reportType={reportType} isAdmin={isAdmin} />
                    ) : (
                        <TargetsOfInterestPopup feature={feature.properties} reportType={reportType} />
                    )}
                </QueryClientProvider>
            );
            let popup = new mapboxgl.Popup({ offset: 15 });

            // set popup on map
            popupRef.current = popup.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(currMap);
            popup.on('close', (e: any) => {
                setHiglightedFeatureVisibility(false);
            });
        }
    });
};

const addCircleLayer = (
    currMap: mapboxgl.Map,
    data: any,
    id: string,
    fill: string,
    stroke: string,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    circleRadius: number = 4,
    setHiglightedFeatureVisibility: (visible: boolean) => void,
    popupType?: 'detection' | 'toi',
    isAdmin?: boolean,
    staticObj?: boolean
) => {
    if (currMap && !currMap.getSource(id)) {
        const point_hover = ['all', ['==', ['feature-state', 'hover'], true]];
        // debugger;
        let hoverId = `${id}_hover`;
        currMap
            .addSource(id, {
                type: 'geojson',
                data: data,
            })
            .addLayer({
                id: id,
                type: 'circle',
                source: id,
                layout: {
                    // make layer visible by default
                    visibility: 'none',
                },
                paint: {
                    'circle-radius': circleRadius,
                    'circle-color': fill,
                    'circle-stroke-color': ['case', point_hover, 'white', stroke],
                    'circle-stroke-width': 1,
                    'circle-opacity': 1,
                },
            });
        if (!staticObj) {
            currMap.addSource(hoverId, {
                type: 'geojson',
                data: data,
                generateId: true, // This ensures that all features have unique IDs
            });

            currMap.addLayer({
                id: hoverId,
                type: 'circle',
                source: hoverId,
                layout: {
                    visibility: 'none',
                },
                paint: {
                    'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 12, 3],
                    'circle-stroke-color': fill,
                    'circle-stroke-width': 2,
                    'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.2, 0],
                    'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.4, 0],
                    'circle-color': fill,
                },
            });

            let hoveredItem: number | null = null;

            currMap.on('mousemove', hoverId, (event: any) => {
                currMap.getCanvas().style.cursor = 'pointer';

                if (event.features.length === 0) return;

                if (hoveredItem) {
                    currMap.removeFeatureState({
                        source: hoverId,
                        id: hoveredItem,
                    });
                }

                hoveredItem = event.features[0].id;

                currMap.setFeatureState(
                    {
                        source: hoverId,
                        id: hoveredItem ? hoveredItem : 'hei',
                    },
                    {
                        hover: true,
                    }
                );
            });
            currMap.on('click', function (e) {
                var features = currMap.queryRenderedFeatures(e.point, { layers: [hoverId] });
                if (!features.length) {
                    return;
                }
                var feature = features[0];
                highlightFeature(currMap, feature, fill, setHiglightedFeatureVisibility);
            });
            currMap.on('mouseleave', hoverId, () => {
                if (hoveredItem) {
                    currMap.setFeatureState(
                        {
                            source: hoverId,
                            id: hoveredItem,
                        },
                        {
                            hover: false,
                        }
                    );
                }
                hoveredItem = null;
                // Remove the information from the previously hovered feature from the sidebar

                // Reset the cursor style
                currMap.getCanvas().style.cursor = '';
            });
        }

        popupType &&
            addPopup(
                currMap,
                id,
                popupRef,
                ReportType.PathfinderReport,
                popupType,
                setHiglightedFeatureVisibility,
                isAdmin
            );
    }
};

export const highlightFeature = (
    currMap: mapboxgl.Map,
    feature: mapboxgl.MapboxGeoJSONFeature,
    fill: string,
    setHiglightedFeatureVisibility: (x: boolean) => void
) => {
    if (typeof currMap.getLayer('selectedCircle') !== 'undefined') {
        currMap.removeLayer('selectedCircle');
        currMap.removeSource('selectedCircle');
    }
    currMap.addSource('selectedCircle', {
        type: 'geojson',
        data: feature,
    });
    currMap.addLayer({
        id: 'selectedCircle',
        type: 'circle',
        source: 'selectedCircle',
        layout: {
            // make layer visible by default
            visibility: 'visible',
        },
        paint: {
            'circle-radius': 20,
            'circle-stroke-color': fill,
            'circle-stroke-width': 2,
            'circle-opacity': 0.4,
            'circle-stroke-opacity': 0.4,

            // The feature-state dependent circle-color expression will render
            // the color according to its magnitude when
            // a feature's hover state is set to true
            'circle-color': fill,
        },
    });
    setHiglightedFeatureVisibility(true);
};

const setLayerVisibility = (currMap: mapboxgl.Map, visible: Boolean, layer_id: string) => {
    let visibility = visible ? 'visible' : 'none';
    if (layerExists(currMap, layer_id)) {
        currMap.setLayoutProperty(layer_id, 'visibility', visibility);
        if (layerExists(currMap, `${layer_id}_hover`)) {
            currMap.setLayoutProperty(`${layer_id}_hover`, 'visibility', visibility);
        }
    }
};

const layerExists = (currMap: mapboxgl.Map, layer: string) => {
    return !!currMap.getLayer(layer);
};
