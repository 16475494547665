import { Dispatch, FC, useRef, useState, useEffect, SetStateAction } from 'react';
import styled from 'styled-components';
import useMeasure from 'react-use-measure';
import syncMove from '@mapbox/mapbox-gl-sync-move';
import mapboxgl from 'mapbox-gl';
import constants from '../../../shared/constants';

type ISliderPos = {
    top: number;
    left: number;
};

type IClipMeasure = {
    top: number;
    right: number;
    bottom: number;
    left: number;
};
const MapContainer = styled.div`
    width: 100vw;
    height: 100vh;
`;
const BeforeMapContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
`;
const AfterMapContainer = styled(BeforeMapContainer)<IClipMeasure>`
    clip: ${(p) => `rect(${p.top}px,${p.right}px,${p.bottom}px,${p.left}px )`};
    cursor: pointer;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const MapDiv = styled.div`
    width: 100%;
    height: 100%;
`;

const SliderDiv = styled.div<ISliderPos>`
    padding: 10px 50px;
    div {
        background: #ff6ebd;
        width: 10px;
        height: 50px;
        border-radius: 6px;
    }
    z-index: 80;
    position: fixed;
    top: ${(p) => `${p.top - 25}px`};
    left: ${(p) => `${p.left - 55}px`};
    cursor: pointer;
    :hover {
        background: url('./images/Arrows.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;
const VakeLogo = styled.div<IClipMeasure>`
    position: fixed;
    left: ${(p) => `${p.left + 10}px`};
    top: ${(p) => `${p.bottom - 30}px`};
    z-index: 1000;
    background-image: url('./images/vakeLogo.svg');
    width: 80px;
    height: 30px;
    background-repeat: no-repeat;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

interface IClickedElement {
    resizeRight: boolean;
    resizeLeft: boolean;
}

interface IClipBounds {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export const BoundingboxSelection: FC<{
    setAoiBounds: Function;
    clipBounds: IClipBounds | null;
    setClipBounds: Dispatch<SetStateAction<IClipBounds | null>>;
}> = ({ setAoiBounds, clipBounds, setClipBounds }) => {
    const BeforeRef = useRef<HTMLDivElement>(null);
    const AfterRef = useRef<HTMLDivElement>(null);
    const [beforeMap, setBeforeMap] = useState<null | mapboxgl.Map>(null);
    const [afterMap, setAfterMap] = useState<null | mapboxgl.Map>(null);
    const [refBefore, beforeBounds] = useMeasure();

    const [clicked, setClicked] = useState<IClickedElement>({
        resizeLeft: false,
        resizeRight: false,
    });

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let newX = e.clientX;
        if (clicked.resizeLeft) {
            setClipBounds((currentState) => {
                if (currentState) {
                    return {
                        ...currentState,
                        left: newX,
                    } as IClipBounds;
                }
                return { left: newX, top: 0, bottom: 0, right: 0 };
            });
        } else if (clicked.resizeRight) {
            setClipBounds((currentState) => {
                if (currentState) {
                    return {
                        ...currentState,
                        right: newX,
                    };
                }
                return { left: newX, top: 0, bottom: 0, right: 0 };
            });
        }
    };

    useEffect(() => {
        if (AfterRef.current) {
            mapboxgl.accessToken =
                'pk.eyJ1IjoiZnJlZHJpa2xiIiwiYSI6ImNra3prMmcyNjBtejgyb3BnOWtlZTVrMWkifQ.XsPsCJLMijjRIsPm63hY6w';
            let map = new mapboxgl.Map({
                container: AfterRef.current,
                center: {
                    lat: 21.2,
                    lng: 113.66455078125,
                },
                zoom: 6,
                style: 'mapbox://styles/fredriklb/ckl235rhc0md917qncnk25jf3',
            });
            setAfterMap(map);
        }
    }, [AfterRef]);
    useEffect(() => {
        if (BeforeRef.current) {
            mapboxgl.accessToken =
                'pk.eyJ1IjoiZnJlZHJpa2xiIiwiYSI6ImNra3prMmcyNjBtejgyb3BnOWtlZTVrMWkifQ.XsPsCJLMijjRIsPm63hY6w';
            let map = new mapboxgl.Map({
                container: BeforeRef.current,
                center: {
                    lat: 21.2,
                    lng: 113.66455078125,
                },
                zoom: 6,
                style: 'mapbox://styles/fredriklb/ckkzk5ezk0pjv17n621woglkb',
            });

            setBeforeMap(map);
            map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        }
    }, [BeforeRef]);

    useEffect(() => {
        if (beforeMap && afterMap) {
            syncMove(beforeMap, afterMap);
        }
    }, [beforeMap, afterMap]);

    useEffect(() => {
        if (beforeBounds.width !== 0 && beforeBounds.height !== 0 && !clipBounds) {
            let top = beforeBounds.height * 0.2;
            let right = beforeBounds.width * 0.8;
            let bottom = beforeBounds.height * 0.8;
            let left = beforeBounds.width * 0.5;

            setClipBounds({ top, right, bottom, left });
        }
    }, [beforeBounds, clipBounds, setClipBounds]);

    useEffect(() => {
        if (clipBounds && beforeMap) {
            let { left, right, top, bottom } = clipBounds;
            let topLeft = beforeMap.unproject(new mapboxgl.Point(left, top));
            let topRight = beforeMap.unproject(new mapboxgl.Point(right, top));
            let bottomRight = beforeMap.unproject(new mapboxgl.Point(right, bottom));
            let bottomLeft = beforeMap.unproject(new mapboxgl.Point(left, bottom));
            let pol = `POLYGON((${topLeft.lng} ${topLeft.lat}, ${topRight.lng} ${topRight.lat}, ${bottomRight.lng} ${bottomRight.lat}, ${bottomLeft.lng} ${bottomLeft.lat}, ${topLeft.lng} ${topLeft.lat} ))`;
            setAoiBounds(pol);
        }
    }, [clipBounds, beforeMap, setAoiBounds]);

    return (
        <MapContainer>
            {clipBounds ? (
                <>
                    <VakeLogo {...clipBounds} />
                    <SliderDiv
                        top={(clipBounds.top + clipBounds.bottom) / 2}
                        left={clipBounds.left}
                        onMouseDown={(e) =>
                            setClicked({
                                resizeLeft: true,
                                resizeRight: false,
                            })
                        }
                        onMouseMove={(e) => onMouseMove(e)}
                        onMouseUp={(e) =>
                            setClicked({
                                resizeLeft: false,
                                resizeRight: false,
                            })
                        }
                    >
                        <div></div>
                    </SliderDiv>
                    <SliderDiv
                        top={(clipBounds.top + clipBounds.bottom) / 2}
                        left={clipBounds.right}
                        onMouseDown={() => {
                            setClicked({
                                resizeLeft: false,
                                resizeRight: true,
                            });
                        }}
                        onMouseMove={(e) => onMouseMove(e)}
                        onMouseUp={() =>
                            setClicked({
                                resizeLeft: false,
                                resizeRight: false,
                            })
                        }
                    >
                        <div />
                    </SliderDiv>
                </>
            ) : null}
            <BeforeMapContainer ref={refBefore}>
                <MapDiv ref={BeforeRef} />
            </BeforeMapContainer>
            <AfterMapContainer
                right={clipBounds ? clipBounds.right : 0}
                left={clipBounds ? clipBounds.left : 0}
                top={clipBounds ? clipBounds.top : 0}
                bottom={clipBounds ? clipBounds.bottom : 0}
            >
                <MapDiv ref={AfterRef} />
            </AfterMapContainer>
        </MapContainer>
    );
};
