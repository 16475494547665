import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import NotFound from '../pages/NotFound';
import { useValidateReportId } from '../requests/reports';
import styled from 'styled-components';
import { Spinner } from '../shared/styles/Spinner';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;

// Reports not requiring login
// Remember to add this whitelisting to both landing page and API.
const whiteListedReports = [
    '477722100.0_2022-10-29T00:13:37_2023-01-26T16:24:27_v3_linear',
    '737e0ce7-e93f-45f9-8dfa-cbd2b06360f6',
];

const getReportTypeByUrlPath = (pathName: string) => {
    return /admin/.test(pathName)
        ? 'admin'
        : /search/.test(pathName)
        ? 'search'
        : /overwatch|report/.test(pathName)
        ? 'overwatch'
        : /pathfinder/.test(pathName)
        ? 'pathfinder'
        : /visualize_candidates/.test(pathName)
        ? 'visualize_candidates'
        : /clearview/.test(pathName)
        ? 'clearview'
        : 'story';
};

export const ProtectedContent: React.FC = () => {
    const { token } = useAuth();
    const location = useLocation();
    const params = useParams();

    const pathName = location.pathname;
    const reportTypeUrlString = getReportTypeByUrlPath(pathName);
    const isWhitelisted: boolean = params.id !== undefined && whiteListedReports.includes(params.id);
    const validationNeeded =
        isWhitelisted && !['visualize_candidates', 'clearview', 'admin', 'search'].includes(reportTypeUrlString);

    const { data: isValid, isLoading } = useValidateReportId(params, reportTypeUrlString, validationNeeded);

    if (!(isWhitelisted || token)) {
        // user is not authenticated
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    if (validationNeeded && isLoading) {
        return (
            <Wrapper>
                <AbsoluteSpinner />
            </Wrapper>
        );
    }

    if (validationNeeded && (!params.id || !isValid)) {
        return <NotFound location={window.location.href} />;
    }

    return <Outlet />;
};
