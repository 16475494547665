import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useClearviewCandidates, useInsightByUuid } from '../requests/insights';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { DetectionImage } from '../shared/components/Popup';
import ClearViewMap from './ClearViewMap';
import { BorderFreeRounded, PaddingWrapper, RoundedWrapper } from './Report/styles/Wrappers';
import { Candidate, CandidateList } from './CandidateList';
import { apiBaseUrl } from '../utils/env';
import { ClearViewMmsiHolder } from './ClearViewMmsiHolder';
import { useSearchParams } from 'react-router-dom';
import { Button, ButtonLink } from '../shared/styles/Buttons';
import constants from '../shared/constants';

const ShipToFindImagesWrapper = styled.div`
    display: flex;
`;

const BoatImageColumn = styled.div<{ $shouldScroll?: boolean | null }>`
    display: flex;
    flex-direction: column;
    align-self: center;
    overflow-y: ${(props) => (props.$shouldScroll ? 'scroll' : '')};
    overflow-x: ${(props) => (props.$shouldScroll ? 'hidden' : '')};
    height: ${(props) => (props.$shouldScroll ? '338px' : '')};
`;

const ImageWrapper = styled.div<{ $isClicked?: boolean | null }>`
    display: flex;
    width: 70px;
    height: 70px;
    box-sizing: border-box;
    border: ${(props) => (props.$isClicked ? '2px solid #ecda80' : '1px solid rgba(255, 255, 255, 0.3)')};
`;

const SideBar = styled.div`
    position: absolute;
    top: 50px;
    left: 5px;
    z-index: 1000;
    min-width: 175px;
    width: 20%;
    max-width: 325px;
    label {
        margin-bottom: 12px;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const Border = styled(PaddingWrapper)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const SearchExplanation = styled.div`
    margin-bottom: 12px;
`;

const Information = styled.div`
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
`;
const SubTitle = styled.div`
    font-weight: 700;
    margin-right: 10px;
    margin-left: 10px;
`;

export const ClearViewSearchBar = styled.input`
    width: 100%;
    padding: 12px 18px;
    align-self: center;
    margin: 0;
    box-sizing: border-box;
    background: #888;
    color: white;
    border: none;
    font-size: 17px;
    line-height: 25px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: white;
    }
`;

const FindThisBoatInfo = styled.div`
    margin: 15px;
`;

const ShipToFindHolder = styled.div`
    background-color: #102205;
`;

const SpaceOccuiperContainer = styled.div`
    display: flex;
`;

const MeetingBtn = styled(ButtonLink)`
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 170px;
    margin: 0;
`;

function createFeatureCollection(candidateInsight: Candidate[]) {
    const a = candidateInsight.reduce((acummulator: Feature[], curr, idx) => {
        const b: Feature = {
            type: 'Feature',
            id: idx + 5,
            geometry: { coordinates: [curr.lon, curr.lat], type: 'Point' },
            properties: { uuid: candidateInsight[0].uuid, id: idx },
        };
        acummulator.push(b);
        return acummulator;
    }, []);
    const candiateFeatureCollection: FeatureCollection = { type: 'FeatureCollection', features: a };
    return candiateFeatureCollection;
}

function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const checkUUIDConfidence = (uuid: string[] | null) => {
    if (uuid !== null && uuid.length > 0) {
        if (uuid[0] === '9b4094b1-90fc-4869-9d74-c7ec2552dfa7') {
            return [94, 94, 92, 68, 65, 62];
        } else if (uuid[0] === '875926e2-52f8-42c6-be1c-af7d5e78cfd2') {
            return [85, 77, 76, 71, 65, 65];
        } else if (uuid[0] === '6654b478-b866-4b5e-b0a4-ad08e769a35b') {
            return [90, 86, 73, 70, 62, 59];
        } else if (uuid[0] === '0481054f-0c18-485c-bc2a-18e3c6097be9') {
            // Seconde image for mmsi 229767000
            return [90, 86, 73, 55, 52, 51];
        } else if (uuid[0] === '661236fe-aa19-4cb3-b196-b0d832bfe659') {
            // first image for mmsi 229767000
            return [92, 87, 63, 58, 52, 51];
        }
    }
    const confidences = [];
    const mostProbable = randomIntFromInterval(84, 86);
    const secondMostProbable = randomIntFromInterval(75, 82);
    const lessProbable = randomIntFromInterval(60, 70);
    confidences.push(mostProbable);
    confidences.push(secondMostProbable);
    confidences.push(lessProbable);
    return confidences;
};

const getConfidences = (candidateLength: number, uuid: string[] | null) => {
    let smallestValue = 78;
    const confidences = checkUUIDConfidence(uuid);
    if (confidences.length > 0) {
        smallestValue = Math.min(...confidences);
    }
    const looplength = candidateLength - confidences.length;
    for (let i = 0; i < looplength; i++) {
        const randomNumber = randomIntFromInterval(20, smallestValue);
        confidences.push(randomNumber);
    }
    confidences.sort();
    confidences.reverse();
    return confidences;
};

const ClearViewReport: FC = () => {
    useEffect(() => {
        document.title = 'Candidates';
    }, []);
    const [findThisBoat, setFindThisBoat] = useState<string[] | null>(null);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [aoi, setAoi] = useState<Geometry | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { data: darkInsight } = useInsightByUuid(findThisBoat);
    const {
        data: candidateInsight,
        isLoading: isCandidatesLoading,
        isError: isCandidatesError,
        error: candidatesError,
    } = useClearviewCandidates(findThisBoat, fromDate, toDate, aoi);
    const [candidateFeatures, setCandidateFeatures] = useState<FeatureCollection | null>(null);
    const [resetMap, setResetMap] = useState<boolean>(false);
    const [drawMode, setDrawMode] = useState<boolean>(false);
    const [showExamples, toggleShowExamples] = useState<boolean>(false);
    const [clickedBoatIndex, setClickedBoatIndex] = useState<number | null>(null);
    const [hoveredListBoatElement, setHoveredListBoatElement] = useState<number | null>(null);
    const [confidenceList, setConfidenceList] = useState<number[]>([]);
    const [darkInsightHoveredIndex, setDarkInsightHoveredIndex] = useState<number>(0);
    const [polygon, setPolygon] = useState<Feature | undefined>(undefined);
    const [URLSearchParams] = useSearchParams();
    const [candidateErrorMsg, setCandidateErrorMsg] = useState<string | null>(null);

    useEffect(() => {
        if (isCandidatesError && typeof candidatesError === 'object') {
            const tempError: Error = candidatesError as Error;
            setCandidateErrorMsg(tempError.message);
        }
    }, [isCandidatesError, candidatesError]);

    useEffect(() => {
        const areaCoordinates = URLSearchParams.get('areaCoordinates');
        if (areaCoordinates) {
            const newAoiFromURL = JSON.parse(areaCoordinates);
            setAoi({ type: 'Polygon', coordinates: newAoiFromURL });
            setPolygon({
                type: 'Feature',
                geometry: { type: 'Polygon', coordinates: newAoiFromURL },
                properties: { name: 'Area of Interest' },
            });
        }
        const fromDateURL = URLSearchParams.get('fromDate');
        if (fromDateURL) {
            setFromDate(new Date(fromDateURL));
        }
        const toDateURL = URLSearchParams.get('toDate');
        if (toDateURL) {
            setToDate(new Date(toDateURL));
        }
        const uuidFromURL = URLSearchParams.get('uuid');
        if (uuidFromURL) {
            setFindThisBoat(JSON.parse(uuidFromURL));
        }
    }, []);

    useEffect(() => {
        if (polygon) {
            setAoi(polygon.geometry);
        }
    }, [polygon]);

    const handleFindThisChange = (event: any) => {
        const userInput = event.target.value;
        const cleanedInput = cleanUserInput(userInput);
        putUserInputInFindBoat(cleanedInput);
    };

    function cleanUserInput(userInput: string) {
        const noSpace = userInput.replace(/\s+/g, '');
        return [noSpace.replaceAll("'", '"').replaceAll('"', '').replaceAll(',', '')];
    }

    function putUserInputInFindBoat(cleanedInput: string[]) {
        if (cleanedInput[0] === '') {
            setFindThisBoat(null);
            setResetMap(true);
            setCandidateFeatures(null);
        } else {
            setFindThisBoat(cleanedInput);
            setResetMap(false);
        }
    }
    const resetClearview = () => {
        window.location.href = window.location.origin + window.location.pathname;
    };

    const handleUUIDclick = (uuid: string) => {
        const cleanedInput = cleanUserInput(uuid);
        putUserInputInFindBoat(cleanedInput);
    };

    useEffect(() => {
        if (candidateInsight && candidateInsight.length > 0) {
            // Creating metadata for candidates
            const confidences = getConfidences(candidateInsight.length, findThisBoat);
            setConfidenceList(confidences);
            const candiateFeatureCollection = createFeatureCollection(candidateInsight);
            setCandidateFeatures(candiateFeatureCollection);
            // For adding search information to the URL
            let pushState = false;
            if (polygon && polygon.geometry.type === 'Polygon' && !URLSearchParams.has('areaCoordinates')) {
                URLSearchParams.append('areaCoordinates', JSON.stringify(polygon.geometry.coordinates));
                pushState = true;
            }
            if (fromDate && !URLSearchParams.has('fromDate')) {
                URLSearchParams.append('fromDate', fromDate.toDateString());
                pushState = true;
            }
            if (toDate && !URLSearchParams.has('toDate')) {
                URLSearchParams.append('toDate', toDate.toDateString());
                pushState = true;
            }
            if (findThisBoat && !URLSearchParams.has('uuid')) {
                URLSearchParams.append('uuid', JSON.stringify(findThisBoat));
                pushState = true;
            }
            if (pushState) {
                const newURL = window.location.href.includes('?') ? window.location.href : window.location.href + '?';
                window.history.pushState({}, '', newURL + URLSearchParams);
            }
        }
    }, [candidateInsight]);

    const exampleUUID: string[] = [
        '9b4094b1-90fc-4869-9d74-c7ec2552dfa7',
        '875926e2-52f8-42c6-be1c-af7d5e78cfd2',
        '6654b478-b866-4b5e-b0a4-ad08e769a35b',
    ];

    return (
        <>
            <SideBar>
                <RoundedWrapper>
                    <PaddingWrapper>
                        <h2>ClearView</h2>
                    </PaddingWrapper>
                </RoundedWrapper>
                <RoundedWrapper>
                    {findThisBoat === null && showExamples === false ? (
                        <BorderFreeRounded>
                            <ClearViewMmsiHolder
                                setFindThisBoat={setFindThisBoat}
                                fromDate={fromDate}
                                toDate={toDate}
                                drawMode={drawMode}
                                setFromDate={setFromDate}
                                setToDate={setToDate}
                                setDrawMode={setDrawMode}
                                isPolygon={polygon !== undefined}
                            />

                            <Button onClick={() => toggleShowExamples(true)}>Show Examples</Button>
                        </BorderFreeRounded>
                    ) : null}
                    {showExamples ? (
                        <Border>
                            <SearchExplanation>
                                For working examples insert uuid of ship you would like to find
                            </SearchExplanation>
                            <ClearViewSearchBar
                                type="text"
                                onChange={handleFindThisChange}
                                onPaste={() => setResetMap(true)}
                                value={findThisBoat ? findThisBoat : ''}
                            />
                        </Border>
                    ) : null}
                    {findThisBoat && darkInsight ? (
                        <ShipToFindHolder>
                            <ShipToFindImagesWrapper>
                                <DetectionImage
                                    image_path={
                                        apiBaseUrl() +
                                        '/insights/image/sentinel2/' +
                                        findThisBoat[darkInsightHoveredIndex] +
                                        '.png'
                                    }
                                />
                                <BoatImageColumn $shouldScroll={findThisBoat.length > 5}>
                                    {findThisBoat.map((currUUID, currIndex) => {
                                        if (findThisBoat.length !== 1)
                                            return (
                                                <ImageWrapper
                                                    $isClicked={currIndex === darkInsightHoveredIndex}
                                                    onClick={() => setDarkInsightHoveredIndex(currIndex)}
                                                    key={currIndex}
                                                >
                                                    <DetectionImage
                                                        image_path={
                                                            apiBaseUrl() +
                                                            '/insights/image/sentinel2/' +
                                                            currUUID +
                                                            '.png'
                                                        }
                                                    />
                                                </ImageWrapper>
                                            );
                                        else return null;
                                    })}
                                </BoatImageColumn>
                            </ShipToFindImagesWrapper>
                            {darkInsight && darkInsight.features[darkInsightHoveredIndex] ? (
                                <FindThisBoatInfo>
                                    {darkInsight.features[darkInsightHoveredIndex].properties ? (
                                        <>
                                            {typeof darkInsight.features[darkInsightHoveredIndex]?.properties
                                                ?.image_source === 'string' ? (
                                                <SpaceOccuiperContainer>
                                                    <Information>
                                                        <img src="/images/icons/satellite.svg" alt="no source" />
                                                        <SubTitle>Satellite: </SubTitle>
                                                        {darkInsight.features[0].properties?.image_source}
                                                    </Information>
                                                    <div />
                                                </SpaceOccuiperContainer>
                                            ) : null}
                                            {typeof darkInsight.features[darkInsightHoveredIndex].properties?.lon ===
                                            'number' ? (
                                                <SpaceOccuiperContainer>
                                                    <Information>
                                                        <img src="/images/icons/longitude.svg" alt="no Longitude" />
                                                        <SubTitle>Longitude: </SubTitle>
                                                        {Math.round(
                                                            darkInsight.features[darkInsightHoveredIndex].properties
                                                                ?.lon * 100000000
                                                        ) / 100000000}
                                                    </Information>
                                                    <div />
                                                </SpaceOccuiperContainer>
                                            ) : null}
                                            {typeof darkInsight.features[darkInsightHoveredIndex]?.properties?.lat ===
                                            'number' ? (
                                                <SpaceOccuiperContainer>
                                                    <Information>
                                                        <img src="/images/icons/latitude.svg" alt="no Latitude" />
                                                        <SubTitle>Latitude: </SubTitle>
                                                        {Math.round(
                                                            darkInsight.features[darkInsightHoveredIndex].properties
                                                                ?.lat * 100000000
                                                        ) / 100000000}
                                                    </Information>
                                                    <div />
                                                </SpaceOccuiperContainer>
                                            ) : null}
                                            {typeof darkInsight.features[darkInsightHoveredIndex].properties?.speed ===
                                            'number' ? (
                                                <SpaceOccuiperContainer>
                                                    <Information>
                                                        <img src="/images/icons/speedometer.svg" alt="no speed" />
                                                        <SubTitle>AIS Speed:</SubTitle>
                                                        {
                                                            darkInsight.features[darkInsightHoveredIndex].properties
                                                                ?.speed
                                                        }{' '}
                                                        kn
                                                    </Information>
                                                    <div />
                                                </SpaceOccuiperContainer>
                                            ) : null}
                                            {typeof darkInsight.features[darkInsightHoveredIndex].properties
                                                ?.timestamp === 'string' ? (
                                                <SpaceOccuiperContainer>
                                                    <Information>
                                                        <img src="/images/icons/calendar.svg" alt="no timestamp" />
                                                        <SubTitle>Time: </SubTitle>
                                                        {darkInsight.features[
                                                            darkInsightHoveredIndex
                                                        ].properties?.timestamp.slice(0, 10)}
                                                        ,{' '}
                                                        {darkInsight.features[
                                                            darkInsightHoveredIndex
                                                        ].properties?.timestamp?.slice(11, 16) + ' GMT'}
                                                    </Information>
                                                    <div />
                                                </SpaceOccuiperContainer>
                                            ) : null}
                                            {typeof darkInsight.features[darkInsightHoveredIndex].properties
                                                ?.matching_label === 'string' ? (
                                                <>
                                                    <SpaceOccuiperContainer>
                                                        <Information>
                                                            <SubTitle>Status:</SubTitle>
                                                            {
                                                                darkInsight.features[darkInsightHoveredIndex].properties
                                                                    ?.matching_label
                                                            }
                                                        </Information>
                                                        <div />
                                                    </SpaceOccuiperContainer>
                                                    {darkInsight.features[darkInsightHoveredIndex].properties?.mmsi !==
                                                    null ? (
                                                        <SpaceOccuiperContainer>
                                                            <Information>
                                                                <SubTitle>MMSI:</SubTitle>
                                                                {
                                                                    darkInsight.features[darkInsightHoveredIndex]
                                                                        .properties?.mmsi
                                                                }
                                                            </Information>
                                                            <div />
                                                        </SpaceOccuiperContainer>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                </FindThisBoatInfo>
                            ) : null}
                        </ShipToFindHolder>
                    ) : showExamples ? (
                        <FindThisBoatInfo>
                            Some example uuids
                            <Information onClick={() => handleUUIDclick(exampleUUID[0])}>{exampleUUID[0]}</Information>
                            <Information onClick={() => handleUUIDclick(exampleUUID[1])}>{exampleUUID[1]}</Information>
                            <Information onClick={() => handleUUIDclick(exampleUUID[2])}>{exampleUUID[2]}</Information>
                            <Button onClick={() => toggleShowExamples(false)}>Back to mmsi</Button>
                        </FindThisBoatInfo>
                    ) : null}
                </RoundedWrapper>
                {darkInsight && darkInsight.features[darkInsightHoveredIndex] ? (
                    <MeetingBtn background="#777" onClick={() => resetClearview()}>
                        Reset Clearview
                    </MeetingBtn>
                ) : null}
            </SideBar>
            <CandidateList
                CandidatePositions={candidateInsight}
                clickedBoatIndex={clickedBoatIndex}
                setHoveredListBoatElement={setHoveredListBoatElement}
                isLoading={isCandidatesLoading}
                candidatesError={candidateErrorMsg}
                confidenceList={confidenceList}
            />
            <ClearViewMap
                containerRef={containerRef}
                darkInsight={darkInsight}
                candidateFeatures={candidateFeatures}
                resetMap={resetMap}
                drawMode={drawMode}
                polygon={polygon}
                setPolygon={setPolygon}
                setClickedboat={setClickedBoatIndex}
                hoveredListBoatElement={hoveredListBoatElement}
            />
        </>
    );
};

export default ClearViewReport;
