import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonLink } from '../../../../shared/styles/Buttons';
import { parse } from 'query-string';
import { MoreInfo } from './MoreInfo';
import { DateSelector } from './DateSelector';
import { RoundedWrapper, PaddingWrapper, ColumnWrapper } from '../../styles/Wrappers';
import { useMetadataQuery } from '../../../../requests/reports';
import constants from '../../../../shared/constants';
import { Spinner } from '../../../../shared/styles/Spinner';

import 'react-datepicker/dist/react-datepicker.css';
import { Insights, ReportMetadata } from '../../../../utils/interfaces';
import { ReportType } from '../../../../utils/enums';
import { SearchForMmsi } from './SearchForMmsi';
import { Link, useLocation, useParams } from 'react-router-dom';
import { QuestionBall } from '../../components/PathfinderLeftSidebar';

const SideBar = styled.div`
    position: absolute;
    top: 50px;
    left: 5px;
    z-index: 1000;
    min-width: 175px;
    width: 25%;
    max-width: 325px;
    label {
        margin-bottom: 12px;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const MeetingBtn = styled(ButtonLink)`
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
`;

const Mobile = styled(RoundedWrapper)`
    @media screen and (min-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;
const BannerRow = styled.div<{ background?: string }>`
    display: flex;
    padding: 25px;
    align-items: center;
    justify-content: space-between;
    background: ${(props) => (props.background ? props.background : constants.colors.pallet.lightBlack)};
`;
const BottomBanner = styled.div<{ background?: string }>`
    position: fixed;
    bottom: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 100vw;
    ${BannerRow} {
        padding: 5px 15px;
    }
`;

export const ArrowWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const Arrow = styled.div<{ disabled?: Boolean }>`
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    img {
        opacity: ${(props) => props.disabled && '0.5'};
    }
    &:hover {
        box-shadow: ${(props) => !props.disabled && 'inset 0 0 100px 100px rgba(255, 255, 255, 0.2)'};
    }
`;

const Border = styled(PaddingWrapper)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const Title = styled(PaddingWrapper)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: row;
`;
export interface IReportInfo {
    index: number;
    setIndex: Function;
    availableDates: Date[];
    currMap: undefined | mapboxgl.Map;
    insights: Insights | undefined;
    popupRef: React.MutableRefObject<mapboxgl.Popup>;
    visibleLayers: string[] | undefined;
    setVisibleLayers: (layers: string[] | undefined) => void;
    tour: any;
}

export const ReportInfo: FC<IReportInfo> = (props) => {
    const { availableDates, setIndex, index, currMap, insights, popupRef, visibleLayers, setVisibleLayers, tour } =
        props;
    const [openDatePicker, setOpenDatePicker] = useState<Boolean>(false);
    const params = useParams();
    const location = useLocation();
    const searchParams = parse(location.search);
    const { data: vakeData } = useMetadataQuery(params, ReportType.InteractiveReport, searchParams);
    const [reportName, setReportName] = useState<string>('Loading..');
    const [metaData, setMetaData] = useState<undefined | ReportMetadata>(undefined);
    const [lastItem, setLastItem] = useState<Boolean>(false);

    // useEffect for setting last item true or false
    useEffect(() => {
        if (availableDates!.length - 1 === index) {
            setLastItem(true);
        } else {
            setLastItem(false);
        }
    }, [availableDates, index]);

    const prevLayer = () => {
        setOpenDatePicker(false);
        if (index > 0) {
            setIndex(index - 1);
        }
    };
    const nextLayer = () => {
        setOpenDatePicker(false);
        if (!lastItem) {
            setIndex(index + 1);
        }
    };

    useEffect(() => {
        if (vakeData && vakeData.metadata && reportName === 'Loading..') {
            setReportName(vakeData.metadata.name);
            setMetaData(vakeData.metadata);
        }
    }, [vakeData, reportName]);

    const chosenDate =
        availableDates && availableDates.length > 0 && index !== -1
            ? availableDates[index].toISOString().split('T')[0]
            : '';

    return (
        <>
            <Mobile>
                <BottomBanner>
                    <BannerRow>
                        {availableDates && availableDates.length === 0 ? (
                            <Spinner />
                        ) : (
                            <>
                                <span>{reportName}</span>
                            </>
                        )}
                    </BannerRow>
                    {availableDates && availableDates.length !== 0 && (
                        <BannerRow background={constants.colors.pallet.blue}>
                            <span>{chosenDate}</span>
                            <ArrowWrapper>
                                <Arrow disabled={index === 0} onClick={() => prevLayer()}>
                                    <img src="/images/icons/ArrowLeft.svg" alt="Previous date" />
                                </Arrow>
                                <Arrow disabled={lastItem} onClick={() => nextLayer()}>
                                    <img src="/images/icons/ArrowRight.svg" alt="Next date" />
                                </Arrow>
                            </ArrowWrapper>
                        </BannerRow>
                    )}
                </BottomBanner>
            </Mobile>
            <SideBar>
                <RoundedWrapper>
                    <Title id="report_name">
                        <h2>{reportName}</h2>
                        <QuestionBall onClick={tour.start}>?</QuestionBall>
                    </Title>
                    {availableDates && availableDates.length > 0 && (
                        <Border id="date_selector">
                            <DateSelector
                                {...props}
                                openDatePicker={openDatePicker}
                                setOpenDatePicker={setOpenDatePicker}
                            />
                        </Border>
                    )}
                    <SearchForMmsi currMap={currMap} reportDetectionsData={insights} popupRef={popupRef} />
                </RoundedWrapper>
                <MoreInfo
                    {...props}
                    reportName={reportName}
                    insights={insights}
                    lastItem={lastItem}
                    metadata={metaData}
                    nextLayer={nextLayer}
                    prevLayer={prevLayer}
                    chosenDate={chosenDate}
                    visibleLayers={visibleLayers}
                    setVisibleLayers={setVisibleLayers}
                />
                {location.pathname === '/report' && (
                    <ColumnWrapper>
                        <MeetingBtn target="_blank" href="https://calendly.com/vake/vake-intro" background="#2C2E33">
                            Book a meeting with us
                        </MeetingBtn>
                        <Link style={{ width: 'inherit' }} to="/report-builder">
                            <Button style={{ width: 'calc(100% - 5px' }} background="white">
                                Request a free Report
                            </Button>
                        </Link>
                    </ColumnWrapper>
                )}
            </SideBar>
        </>
    );
};
