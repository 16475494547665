import { FeatureCollection, Feature, Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { highlightFeature } from '../shared/components/Map';
import { addMouseMoveHoverEffect } from '../utils/map';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const MapContainer = styled.div<{ isLoadingData: Boolean }>`
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isLoadingData ? 0.5 : 1)};
    z-index: -1;
`;

const ClearViewMap: React.FC<{
    containerRef: React.RefObject<HTMLDivElement>;
    darkInsight: FeatureCollection | undefined;
    candidateFeatures: FeatureCollection | null;
    resetMap: boolean;
    drawMode: boolean;
    polygon: Feature | undefined;
    hoveredListBoatElement: number | null;
    setClickedboat: (value: number | null) => void;
    setPolygon: (value: Feature | undefined) => void;
}> = ({
    containerRef,
    darkInsight,
    candidateFeatures,
    resetMap,
    drawMode,
    polygon,
    hoveredListBoatElement,
    setClickedboat,
    setPolygon,
}) => {
    const [currMap, setMap] = useState<undefined | mapboxgl.Map>(undefined);
    const [drawObj, setDrawObj] = useState<null | MapboxDraw>(null);
    const [getPolygon, setGetPolygon] = useState<boolean>(false); // State needed to add and delete eventlistener
    const darkInsightLayerId = 'dark_insight';
    const candidateInsightLayerId = 'candidate_insight';
    useEffect(() => {
        if (containerRef.current && !currMap) {
            const coords = {
                lat: 57.33870347771735,
                lng: 2.042840975438542,
            };
            mapboxgl.accessToken =
                'pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw';
            const maps: mapboxgl.Map = new mapboxgl.Map({
                container: containerRef.current,
                center: coords,
                zoom: 5,
                style: 'mapbox://styles/vake-torstein/cl4xzn1gs000p14qgz8nqs0ms',
            });
            maps.on('load', () => {
                maps.loadImage('/images/icons/ArrowRight.png', (error: any, image: any) => {
                    if (error) {
                        throw error;
                    }
                    maps.addImage('arrow_right', image);
                });
                setMap(maps);
                maps.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
                maps.addControl(new mapboxgl.ScaleControl({ maxWidth: 500 }));
            });
        }
    }, [currMap, containerRef]);

    /**
     * Part under is for drawing logic
     */

    useEffect(() => {
        // useEffect for adding layers to map when draw mode is toggled
        if (currMap) {
            if (drawMode) {
                // New drawingmode
                const draw = new MapboxDraw({
                    displayControlsDefault: false,
                    // Select which mapbox-gl-draw control buttons to add to the map.
                    controls: {
                        polygon: true,
                        trash: true,
                    },
                    defaultMode: 'draw_polygon',
                });
                setDrawObj(draw);
            } else {
                // Drawing mode off. Resett everything
                setPolygon(undefined);
                currMap.off('draw.create', drawCreate); // Removing event listener. Needs same function given to the eventadding
                if (currMap && typeof currMap.getLayer('aoi_outline') !== 'undefined') {
                    currMap.removeLayer('aoi_outline');
                    currMap.removeLayer('aoi_fill');
                    currMap.removeSource('aoi');
                }
                setGetPolygon(false);
                if (drawObj && !!!polygon) {
                    // If the user deactivates drawing after not finishing polygon
                    currMap.removeControl(drawObj);
                    setDrawObj(null);
                }
            }
        }
    }, [currMap, drawMode]);

    useEffect(() => {
        if (currMap && polygon) {
            AddAoI(currMap, polygon);
        }
    }, [polygon, currMap]);

    useEffect(() => {
        // useEffect made for
        if (drawObj && getPolygon) {
            const newAoI: Geometry = drawObj.getAll()['features'][0]['geometry'];
            setPolygon({ type: 'Feature', geometry: newAoI, properties: { name: 'Area of Interest' } });
        }
    }, [getPolygon]);

    const drawCreate = () => {
        setGetPolygon(!getPolygon);
    };

    useEffect(() => {
        // When the draw object has been created or removed, cleanup
        if (currMap) {
            if (drawObj && drawMode) {
                // Add eventlistener and control to map
                currMap.addControl(drawObj, 'bottom-left');
                currMap.on('draw.create', drawCreate);
            } else if (drawObj) {
                // Remove eventllistener and control
                currMap.removeControl(drawObj);
                setDrawObj(null);
            }
        }
    }, [drawObj]);

    useEffect(() => {
        if (currMap && polygon && drawObj) {
            AddAoI(currMap, polygon);
            currMap.removeControl(drawObj);
        }
    }, [polygon, setPolygon]);

    /**
     * Part over is for drawing logic
     */

    useEffect(() => {
        if (currMap && darkInsight && candidateFeatures) {
            addTargetsOfInterestLayerToMap(currMap, darkInsight, darkInsightLayerId, '#339955', '#33ff55', false);
            addTargetsOfInterestLayerToMap(
                currMap,
                candidateFeatures,
                candidateInsightLayerId,
                '#bb7',
                '#ff7',
                true,
                setClickedboat
            );
            addClustering(currMap, candidateInsightLayerId);
            if (currMap && candidateFeatures && candidateFeatures.features[0].geometry.type === 'Point') {
                const endCoordinates = candidateFeatures.features[0].geometry.coordinates;
                const end = { center: { lng: endCoordinates[0], lat: endCoordinates[1] }, pitch: 0, bearing: 0 };
                currMap?.flyTo({ ...end, duration: 2022, essential: true });
            }
        }
    }, [currMap, darkInsight, candidateFeatures]);

    useEffect(() => {
        if (resetMap) {
            if (currMap && typeof currMap.getLayer('clusters') !== 'undefined') {
                currMap.removeLayer('clusters');
                currMap.removeLayer('point-count');
            }
            if (currMap && typeof currMap.getLayer(darkInsightLayerId) !== 'undefined') {
                currMap.removeLayer(darkInsightLayerId);
                currMap.removeLayer(darkInsightLayerId + '_hover');
                currMap.removeSource(darkInsightLayerId);
            }
            if (currMap && typeof currMap.getLayer(candidateInsightLayerId) !== 'undefined') {
                currMap.removeLayer(candidateInsightLayerId);
                currMap.removeLayer(candidateInsightLayerId + '_hover');
                currMap.removeSource(candidateInsightLayerId);
            }
            if (currMap && typeof currMap.getLayer('aoi_outline') !== 'undefined') {
                currMap.removeLayer('aoi_outline');
                currMap.removeLayer('aoi_fill');
                currMap.removeSource('aoi');
            }
        }
    }, [resetMap]);

    useEffect(() => {
        if (typeof hoveredListBoatElement === 'number') {
            if (candidateFeatures && currMap) {
                const candidate = candidateFeatures.features[hoveredListBoatElement];
                const tempFeature: mapboxgl.MapboxGeoJSONFeature = {
                    layer: { id: '-', type: 'circle' },
                    source: '-',
                    sourceLayer: '-',
                    state: { hover: true },
                    geometry: candidate.geometry,
                    properties: candidate.properties,
                    type: candidate.type,
                };
                highlightFeature(currMap, tempFeature, 'orange', (hei: boolean) => true);
            }
        } else {
            if (currMap && typeof currMap.getLayer('selectedCircle') !== 'undefined') {
                currMap.removeLayer('selectedCircle');
                currMap.removeSource('selectedCircle');
            }
        }
    }, [currMap, hoveredListBoatElement, candidateFeatures]);

    return <MapContainer ref={containerRef} isLoadingData={false} />;
};
const addClustering = (currMap: mapboxgl.Map, layerId: string) => {
    if (currMap && currMap.getSource(layerId)) {
        const circleRadius = 6;
        currMap.addLayer({
            id: 'clusters',
            type: 'circle',
            source: layerId,
            filter: ['has', 'point_count'],
            layout: {
                visibility: 'visible',
            },
            paint: {
                'circle-radius': ['step', ['get', 'point_count'], circleRadius, 1, circleRadius * 2],
                'circle-color': ['step', ['get', 'point_count'], '#f0d971', 1, '#fad060', 6, '#ffC060'],

                'circle-stroke-width': 1,
                'circle-opacity': 1,
            },
        });
        currMap.addLayer({
            id: 'point-count',
            type: 'symbol',
            source: layerId,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
            },
        });
        currMap.on('click', 'clusters', (e) => {
            const features = currMap.queryRenderedFeatures(e.point, {
                layers: ['clusters'],
            });
            const clusterId = features[0].properties?.cluster_id;
            const source: mapboxgl.GeoJSONSource = currMap.getSource(layerId) as mapboxgl.GeoJSONSource;
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;
                if (features[0].geometry.type === 'Point') {
                    currMap.easeTo({
                        center: {
                            lon: features[0].geometry?.coordinates[0],
                            lat: features[0].geometry?.coordinates[1],
                        },
                        zoom: zoom,
                        duration: 1300,
                    });
                }
            });
        });
    }
};

const addTargetsOfInterestLayerToMap = (
    currMap: mapboxgl.Map,
    item: FeatureCollection,
    layerId: string,
    normal_color: string,
    hover_color: string,
    cluster: boolean,
    setClickedboat?: (value: number | null) => void
) => {
    // let targets_of_interest_detection_id = `targets_of_interest_detection_${item}`;
    if (currMap && !currMap.getSource(layerId)) {
        currMap
            .addSource(layerId, {
                type: 'geojson',
                data: item,
                cluster: cluster ? true : false,
                clusterMaxZoom: 10,
                clusterRadius: 12,
            })
            .addLayer({
                id: layerId + '_hover',
                type: 'circle',
                source: layerId,
                layout: {
                    // make layer visible by default
                    visibility: 'visible',
                },
                paint: {
                    'circle-radius': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        12, // when hover
                        6, // when normal
                    ],
                    'circle-color': normal_color,
                    'circle-stroke-color': '#000',
                    'circle-stroke-width': 2,
                    'circle-stroke-opacity': 0.2,
                },
            })
            .addLayer({
                id: layerId,
                type: 'circle',
                source: layerId,
                layout: {
                    // make layer visible by default
                    visibility: 'visible',
                },
                paint: {
                    'circle-radius': 6,
                    'circle-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        hover_color, // when hover
                        normal_color, // when normal
                    ],
                    'circle-stroke-color': '#000',
                    'circle-stroke-width': 2,
                    'circle-stroke-opacity': 0.2,
                },
            });
        if (setClickedboat) {
            currMap.on('click', function (e) {
                var features = currMap.queryRenderedFeatures(e.point, { layers: [layerId] });
                if (!features.length) {
                    setClickedboat(null);
                    return;
                }
                var feature = features[0];
                if (feature.properties && feature.properties.id) {
                    setClickedboat(feature.properties.id);
                }
            });
        }
        addMouseMoveHoverEffect(currMap, layerId);
    }
};

const AddAoI = (currMap: mapboxgl.Map, polygon: Feature) => {
    if (currMap && !currMap.getSource('aoi')) {
        currMap
            .addSource('aoi', {
                type: 'geojson',
                data: polygon,
            })
            .addLayer({
                id: 'aoi_outline',
                type: 'line',
                source: 'aoi',
                layout: {
                    visibility: 'visible',
                },
                paint: {
                    'line-color': '#555',
                    'line-width': 3,
                },
            })
            .addLayer({
                id: 'aoi_fill',
                type: 'fill',
                source: 'aoi', // reference the data source
                layout: {},
                paint: {
                    'fill-color': '#0080ff', // blue color fill
                    'fill-opacity': 0.3,
                },
            });
    }
};

export default ClearViewMap;
