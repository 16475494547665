import styled from 'styled-components';
import constants from '../constants';

export const Button = styled.button<{ background?: string }>`
    background-color: ${(props) => (props.background ? props.background : 'rgba(255, 255, 255, 0.15)')};
    border: none;
    color: ${(props) => (props.background ? constants.colors.pallet.black : constants.colors.pallet.white)};
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px 2px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid ${(props) => (props.background ? props.background : 'rgba(255, 255, 255, 0.2)')};
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
    &:active {
        outline: none;
        border: none;
    }
    &:focus {
        outline: 0;
    }
`;
export const ButtonLink = styled.a<{ background?: string }>`
    background-color: ${(props) => (props.background ? props.background : 'rgba(255, 255, 255, 0.15)')};
    border: none;
    color: ${(props) => (props.background ? constants.colors.pallet.black : constants.colors.pallet.white)};
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px 2px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid ${(props) => (props.background ? props.background : 'rgba(255, 255, 255, 0.2)')};
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
    &:active {
        outline: none;
        border: none;
    }
    &:focus {
        outline: 0;
    }
`;

export const InputButton = styled.input<{ background?: string }>`
    background-color: ${(props) => (props.background ? props.background : 'rgba(255, 255, 255, 0.15)')};
    border: none;
    color: ${(props) => (props.background ? constants.colors.pallet.black : constants.colors.pallet.white)};
    padding: 12px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px 2px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid ${(props) => (props.background ? props.background : 'rgba(255, 255, 255, 0.2)')};
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
    &:active {
        outline: none;
        border: none;
    }
    &:focus {
        outline: 0;
    }
`;

export const NavigationButton = styled.div`
    background-color: ${constants.colors.pallet.black};
    border-radius: 5px;
    padding: 0 7px;
    font-size: 17px;
    line-height: 25px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
`;
