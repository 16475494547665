import { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ReportInfo } from './reportInfo/ReportInfo';
import {
    useMetadataQuery,
    useAvailableDatesQuery,
    useInsightsPerDateQuery,
    useCloudsPerDateQuery,
} from '../../../requests/reports';
import Map from '../../../shared/components/Map';
import { Spinner } from '../../../shared/styles/Spinner';
import { ReportType } from '../../../utils/enums';
import mapboxgl from 'mapbox-gl';
import { parse } from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { useShepherdTour } from 'react-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import { OverWatchSteps } from '../../../utils/tutorials';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;

const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true,
        },
    },
    useModalOverlay: true,
};

export const InteractiveReport: FC = () => {
    const params = useParams();
    const searchParams = parse(useLocation().search);
    const { data: availableDates } = useAvailableDatesQuery(params, searchParams);
    const { data: metaData, isSuccess: isMetadataSuccess } = useMetadataQuery(
        params,
        ReportType.InteractiveReport,
        searchParams
    );
    const [activeDate, setActiveDate] = useState<Date | undefined>(undefined);
    const [index, setIndex] = useState<number>(-1);
    const [map, setMap] = useState<undefined | mapboxgl.Map>(undefined);
    const [visibleLayers, setVisibleLayers] = useState<string[] | undefined>(undefined);
    const popupRef = useRef(new mapboxgl.Popup({ offset: 15 }));
    const tour = useShepherdTour({ tourOptions, steps: OverWatchSteps });

    // set page tab title
    useEffect(() => {
        let title = 'Overwatch';
        if (metaData && metaData.metadata && metaData.metadata.name) {
            title = metaData.metadata.name;
        }
        document.title = title;
    }, [isMetadataSuccess, metaData]);

    // index is set to the latest known position when available dates are initially loaded
    useEffect(() => {
        if (index === -1 && availableDates) setIndex(availableDates.length - 1);
    }, [availableDates, index]);

    // useEffect for setting active date by index
    useEffect(() => {
        if (index !== -1 && availableDates) setActiveDate(availableDates[index]);
    }, [availableDates, index]);

    const { data: clouds } = useCloudsPerDateQuery(params, availableDates![index], searchParams);
    const { data: insights, isLoading: isInsightsLoading } = useInsightsPerDateQuery(
        params,
        availableDates![index], //runs when available dates and valid index is fetched
        searchParams
    );

    // useEffect(() => window.scrollTo(0, 0), []);

    return (
        <Wrapper>
            <ReportInfo
                index={index}
                setIndex={setIndex}
                availableDates={availableDates!}
                insights={insights}
                currMap={map}
                popupRef={popupRef}
                visibleLayers={visibleLayers}
                setVisibleLayers={setVisibleLayers}
                tour={tour}
            />
            {isInsightsLoading && <AbsoluteSpinner />}
            <Map
                insights={insights}
                clouds={clouds}
                metaData={metaData}
                activeDate={activeDate}
                availableDates={availableDates}
                isInsightsLoading={isInsightsLoading}
                map={map}
                popupRef={popupRef}
                setMap={setMap}
                setVisibleLayers={setVisibleLayers}
            />
        </Wrapper>
    );
};
