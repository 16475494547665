import { useQuery } from 'react-query';
import { useAuth } from '../auth/AuthProvider';
import { apiBaseUrl } from '../utils/env';
import { getRequestHeaders } from './helpers';
import { FeatureCollection, Geometry } from 'geojson';
import { Candidate } from '../pages/CandidateList';

const vake_api_server_url = apiBaseUrl();

export const useInsightByUuid = (uuidList: string[] | null) => {
    const { token } = useAuth();
    const uuidListBody = { uuid_list: uuidList };
    const url = `${vake_api_server_url}/insights/uuid`;
    return useQuery<FeatureCollection>(
        ['insights_by_uuid', uuidList],
        async () => {
            const res = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: getRequestHeaders(token),
                body: JSON.stringify(uuidListBody),
            });

            if (res.status === 404) {
                console.log(await res.json());
            }

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
            enabled: !!uuidList,
            cacheTime: 1000 * 60 * 60,
        }
    );
};

export const useClearviewCandidates = (
    uuidList: string[] | null,
    fromDate: Date | null,
    toDate: Date | null,
    aoi: Geometry | null
) => {
    const { token } = useAuth();
    let uuid = null;
    let from = null;
    let to = null;
    uuid = uuidList;
    if (fromDate !== null) {
        from = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate() + '&';
    }
    if (toDate !== null) {
        to = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
    }
    let dasBody = { uuid_list: uuidList, aoi: aoi, from_date: from, to_date: to, top_k: 100 };
    const url = `${vake_api_server_url}/clearview`;
    return useQuery<Candidate[]>(
        ['uuid', uuid],
        async () => {
            const res = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: getRequestHeaders(token),
                body: JSON.stringify(dasBody),
            });

            if (!res.ok) {
                if (res.status === 404) {
                    const errorMessage = await res.json().then((r) => r.detail);
                    throw new Error(errorMessage);
                }
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
            enabled: !!uuidList,
            cacheTime: 1000 * 60 * 60,
            retry: 1,
        }
    );
};

export const useClearviewMmsiExample = (mmsi: number | null) => {
    const { token } = useAuth();
    const url = `${vake_api_server_url}/clearview/mmsi`;
    const mmsiBody = { mmsi: [mmsi] };

    return useQuery<FeatureCollection>(
        ['mmsi', mmsi],
        async () => {
            const res = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: getRequestHeaders(token),
                body: JSON.stringify(mmsiBody),
            });
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            return await res.json();
        },
        {
            staleTime: Infinity,
            enabled: !!mmsi,
            cacheTime: 1000 * 60 * 60,
        }
    );
};
