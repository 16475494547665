import styled from 'styled-components';

export const SectionIntro = styled.span`
    max-width: 580px;
    margin: 30px auto 65px 0;
`;

export const Label = styled.label<{ color?: string }>`
    font-size: 13px;
    line-height: 25px;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : 'white')};
`;
